const details = [
  {
    name: "Owita Organics - Ragama",
    iframeUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.823188592629!2d79.9210762153439!3d7.030058119016008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2f9d45c0684d3%3A0x3e2a95d9827b5562!2sOwita%20Organics!5e0!3m2!1sen!2slk!4v1620921063962!5m2!1sen!2slk",
    phone: "074 13 50 501",
    address: "No. 614, Katagewaththa Road, Ragama.",
  },
  {
    name: "Owita Organics - Nugegoda",
    iframeUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.178855649712!2d79.90932401534383!3d6.869160220952697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25b08252a7e7b%3A0xc761e7bcc25ebfb6!2sOwita%20Organics%20-%20Nugegoda!5e0!3m2!1sen!2slk!4v1620920981000!5m2!1sen!2slk",
    phone: "074 13 50 502",
    address: "No. 61, Madiwela Rd, Nugegoda.",
  },
  // {
  //   name: "Owita Organics - Kandy",
  //   iframeUrl:
  //     "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3957.4770101305494!2d80.63424225091083!3d7.30018181564475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae367072d4c18cb%3A0x48c70c8262d4c9ce!2sOwita%20Organics%20-%20Kandy!5e0!3m2!1sen!2slk!4v1633866103615!5m2!1sen!2slk",
  //   phone: "0760 950 900",
  //   address: "3L, William Gopallawa Mawatha, Kandy",
  // },
]

export default details
